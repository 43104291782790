import { getProductConfig } from 'src/config/product';

import { CONTRACT } from 'src/constants/typologies';

import { SORTING_CRITERION } from 'src/entities/listing/utils/sorting';

import type { AgencyPagePath } from 'src/types/agency';
import type { Language } from 'src/types/translations';
import type { AgencyPageType, AgencyQueryParams } from './types';
import type { RawAgencyPhone } from './types/raw-data-types';

import { nonNullable } from 'src/utils/common';

export const getContractParam = (contractId?: CONTRACT) => {
  switch (contractId) {
    case CONTRACT.VENDITA:
    case CONTRACT.AFFITTO:
      return { 'contract-id': contractId };
    case CONTRACT.ASTE:
      return { auctions: 1 };
    case CONTRACT.VENDUTO_AFFITTATO:
      // return only unsearchable ads
      return { 'ad-state': 3 };
    default:
      // return only searchable ads
      return { 'ad-state': 1 };
  }
};

export const formatAgencyRealEstateListParams = (
  queryParams: AgencyQueryParams
) => {
  const {
    contractId,
    categoryId,
    typeId,
    pag = 1,
    sortBy,
    sortDir,
  } = queryParams;

  const page = typeof pag === 'number' ? pag : 1;
  const limit = getProductConfig('listMaxResultsPerPage');
  const offset = (page - 1) * limit;

  const baseSearchParams = {
    'category-id': categoryId,
    'type-id': typeId ? [...typeId] : undefined,
    limit,
    offset,
    'sort-by': sortBy || SORTING_CRITERION.relevance,
    'sort-dir': sortDir,
  };

  return {
    ...baseSearchParams,
    ...getContractParam(contractId),
  };
};

export type AgencyQueryParamsWithMarketingParams = AgencyQueryParams & {
  marketingParams: { [x: string]: string | boolean | string[] | undefined };
};

export const agencyRealEstateListQueryKey = (
  id: string,
  pageType: AgencyPageType,
  searchParams: AgencyQueryParamsWithMarketingParams,
  lang: Language
) => {
  const params = {
    ...searchParams,
    sortBy: searchParams.sortBy || SORTING_CRITERION.relevance,
  };

  return ['agency-real-estate-list', { id, pageType, ...params, lang }];
};

export const agencySeoQueryKey = (
  id: string,
  pageType: AgencyPageType,
  searchParams: AgencyQueryParamsWithMarketingParams,
  lang: Language
) => {
  const params = {
    ...searchParams,
    sortBy: searchParams.sortBy || SORTING_CRITERION.relevance,
  };

  return ['agency-seo-data', { id, pageType, ...params, lang }];
};

export const getAgencyEndpoint = (origin: string, pageType: AgencyPageType) => {
  switch (pageType) {
    case 'agent': {
      return `${origin}/agent`;
    }

    case 'court': {
      return `${origin}/court`;
    }

    case 'franchising': {
      return `${origin}/franchising`;
    }

    case 'agency': {
      return `${origin}/agency`;
    }
  }
};

export const getPageType = (path: string): AgencyPageType => {
  const agentsPath = getProductConfig('agencyAgentsUrl');
  const franchisingPath = getProductConfig('franchisingUrl');

  // '/pro/{id}/tribunale-di-'
  if (path.includes('/pro/') && path.includes('tribunale-di-')) {
    return 'court' as const;
  }

  // '/agenti-immobiliari/'
  if (agentsPath && path.includes(agentsPath)) {
    return 'agent' as const;
  }

  // '/agenzie-immobiliari/gruppi/'
  if (franchisingPath && path.includes(franchisingPath)) {
    return 'franchising' as const;
  }

  // '/agenzie-immobiliari/', '/pro/', '/imprese-edili/'
  return 'agency' as const;
};

type InfoFromPath =
  | {
      pageType: 'agency' | 'court';
      id: string;
      keyUrl: string | undefined;
    }
  | {
      pageType: 'franchising' | 'agent';
      id: string;
    };

export const getInfoFromPathNew = (
  url: string,
  path: AgencyPagePath
): InfoFromPath => {
  const pageType = getPageType(url);

  const [id, keyUrl] = path;

  return {
    pageType,
    id,
    keyUrl,
  };
};

const sanitizePhone = (
  phone: RawAgencyPhone | undefined,
  comparisonPhone: RawAgencyPhone | undefined,
  invalidValue?: string
) => {
  if (!phone) return undefined;
  if (phone.value === invalidValue) return undefined;
  if (phone.value === comparisonPhone?.value) return undefined;

  return phone;
};

export const getSanitizedPhones = (phones: RawAgencyPhone[]) => {
  // PLEASE NOTE,
  // we currently do not manage two telephone numbers for the same type
  // so with reduce we always take the last value for each type
  const phoneMap = phones.reduce(
    (acc, phone) => {
      acc[phone.type] = phone;

      return acc;
    },
    {} as { [key: string]: RawAgencyPhone | undefined }
  );

  const { vtel, tel1, tel2, cell } = phoneMap;

  // Ubi Banca custom
  const sanitizedTel1 = sanitizePhone(tel1, undefined, '0000000000');
  // Remove duplicate landlines
  const sanitizedTel2 = sanitizePhone(tel2, tel1);
  // Remove duplicate cell phone
  const sanitizedCell = sanitizePhone(cell, tel1);

  const phoneEntities: RawAgencyPhone[] = [
    vtel,
    sanitizedTel1,
    sanitizedTel2,
    sanitizedCell,
  ].filter(nonNullable);

  return phoneEntities;
};
