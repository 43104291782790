import css from './style.module.scss';

type Tag = 'h1' | 'p';

interface CoverTitleProps {
  title: string;
  subtitle?: string;
  tag?: Tag;
}

export function CoverTitle({
  title,
  tag: Tag = 'h1',
  subtitle,
}: CoverTitleProps) {
  return (
    <Tag className={css['in-coverTitle']}>
      <span
        className={css['in-coverTitle__title']}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subtitle && (
        <span
          className={css['in-coverTitle__subtitle']}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </Tag>
  );
}
