import type { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface CoverPageHeaderProps {
  headerBackgroundColor?: string;
  image?: string;
  placeholderImage: ReactNode;
  figureCustomClass?: string;
}

export function CoverPageHeader({
  image,
  headerBackgroundColor,
  placeholderImage,
  figureCustomClass,
  children,
}: PropsWithChildren<CoverPageHeaderProps>) {
  return (
    <div
      className={css['in-coverPageHeader']}
      style={{ backgroundColor: headerBackgroundColor }}
    >
      <PepitaFigure
        customClass={clsx(css['in-coverPageHeader__figure'], figureCustomClass)}
      >
        {image ? (
          <PepitaFigure.Image
            src={image}
            customClass={css['in-coverPageHeader__image']}
          />
        ) : (
          <PepitaFigure.Content>{placeholderImage}</PepitaFigure.Content>
        )}
      </PepitaFigure>

      {children}
    </div>
  );
}
