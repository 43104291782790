import type { AgencyQueryParams } from 'src/repository/agency/types';

import type {
  CATEGORIES,
  CONTRACT,
  TYPOLOGIES,
} from 'src/constants/typologies';

import type {
  SortingCriterion,
  SortingDirection,
} from 'src/entities/listing/types/sorting';
import { SORTING_CRITERION } from 'src/entities/listing/utils/sorting';

import { removeEmptyKeys } from 'src/utils/object';

const getSortByDefaultValue = (sortBy?: SortingCriterion) => {
  if (!sortBy) {
    return SORTING_CRITERION.relevance;
  }

  return sortBy;
};

const formatPagination = (pag?: string): string | number | undefined => {
  if (pag) {
    if (isNaN(parseInt(pag))) {
      return pag;
    }

    return parseInt(pag);
  }

  return;
};

const formatTypeId = (typeId?: string | string[]): TYPOLOGIES[] | undefined => {
  if (!typeId) {
    return;
  }

  if (!Array.isArray(typeId)) {
    return [typeId] as TYPOLOGIES[];
  }

  return typeId as TYPOLOGIES[];
};

export const getAllowedPathParams = (
  params: Record<string, string | string[] | boolean | undefined>
) => {
  const {
    sortBy,
    sortDir,
    typeId,
    pag,
    contractId,
    categoryId,
    ...otherParams
  } = params;

  const pagination = formatPagination(pag as Optional<string>);

  const allowedParams = {
    contractId: contractId as CONTRACT | undefined,
    categoryId: categoryId as CATEGORIES | undefined,
    typeId: formatTypeId(typeId as string | string[] | undefined),
    pag: pagination,
    sortBy: sortBy as SortingCriterion | undefined,
    sortDir: sortDir as SortingDirection,
  };

  return {
    ...removeEmptyKeys(allowedParams),
    marketingParams: otherParams,
  };
};

export const getFormattedSearchParams = (
  searchParams: Record<string, string | string[] | undefined>
): AgencyQueryParams => {
  const { pag, sortBy, typeId, ...otherParams } = searchParams;

  return {
    ...removeEmptyKeys({ ...otherParams, typeId: formatTypeId(typeId) }),
    sortBy: getSortByDefaultValue(sortBy as SortingCriterion | undefined),
    ...(pag && { pag: parseInt(pag as string) }),
  };
};
