import { useRouter } from 'next/router';
import { getInfoFromPathNew } from 'src/repository/agency/utils';

import { useQueryParams } from 'src/hooks/useQueryParams';

import type { AgencyPagePath } from 'src/types/agency';
import type { Language } from 'src/types/translations';

import { getAllowedPathParams } from '../utils/search-params';

export const useAgencyQueryParams = () => {
  const {
    asPath,
    query: { path },
    locale,
  } = useRouter();

  const { pageType, id } = getInfoFromPathNew(asPath, path as AgencyPagePath);

  const queryParams = useQueryParams();
  const searchParams = getAllowedPathParams(queryParams);

  return { ...searchParams, lang: locale as Language, pageType, id };
};
